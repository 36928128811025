<template>
  <div v-if="currentUser.superAdmin">
    <v-data-table
      :headers="headers"
      :items="recommendedCategories"
      :options.sync="pagination"
      :server-items-length="totalRecommendedCategories"
      :loading="loading"
      :footer-props="{
        showFirstLastPage: true,
        'items-per-page-options': [10, 20, 50, 100]
      }"
      class="elevation-1"
    >
      <template v-slot:item.allCities="{ item }">
        <v-switch v-model="item.allCities" disabled></v-switch>
      </template>
      <template v-slot:item.clear="{ item }">
        <v-avatar
          v-if="currentUser.superAdmin || (!currentUser.superAdmin && !item.allCities)"
          color="teal"
          size="26"
          rounded
        >
          <router-link
            :to="{
              name: 'RecommendedCategoryEdit',
              params: { recommendedCategoryId: item.id }
            }"
          >
            <v-icon dark>mdi-pencil</v-icon>
          </router-link>
        </v-avatar>
      </template>
      <template v-slot:item.add="{ item }">
        <v-avatar
          v-if="currentUser.superAdmin || (!currentUser.superAdmin && !item.allCities)"
          color="red darken-4"
          size="26"
          rounded
        >
          <v-icon dark @click="delRecord(item)">mdi-delete</v-icon>
        </v-avatar>
      </template>
      <template v-slot:body.prepend>
        <tr>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-spacer></v-spacer>
          </td>
          <td>
            <v-text-field
              v-model="cityName"
              type="text"
              @keyup.enter="
                page = 1;
                retrieveRecommendedCategories();
              "
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="categoryName"
              type="text"
              @keyup.enter="
                page = 1;
                retrieveRecommendedCategories();
              "
            ></v-text-field>
          </td>
          <td>
            <v-avatar color="indigo" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  retrieveRecommendedCategories();
                "
                >mdi-magnify
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <v-avatar color="grey" size="30">
              <v-icon
                dark
                @click="
                  page = 1;
                  clearSearch();
                "
                >mdi-close
              </v-icon>
            </v-avatar>
          </td>
          <td>
            <router-link :to="{ name: 'RecommendedCategoryCreate' }">
              <v-avatar color="teal" size="26" rounded>
                <v-icon dark>mdi-plus</v-icon>
              </v-avatar>
            </router-link>
          </td>
        </tr>
      </template>
    </v-data-table>
    <ConfirmDialog ref="confirm" />
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ConfirmDialog from "@/view/pages/ConfirmDialog.vue";
import { mapGetters } from "vuex";

export default {
  name: "recommendedCategories",
  computed: {
    ...mapGetters(["currentUser"]),
    name() {
      return this.$i18n.t("commonTable.categories");
    },
    headers() {
      return [
        { text: "Id", align: "start", sortable: true, value: "id" },
        { text: this.$i18n.t("commonTable.startDate"), value: "startDate" },
        { text: this.$i18n.t("commonTable.endDate"), value: "endDate" },
        { text: this.$i18n.t("pages.recommendedCategories.allCities"), value: "allCities" },
        { text: this.$i18n.t("commonTable.city"), value: "cityName" },
        { text: this.$i18n.t("commonTable.category"), value: "categoryName" },
        { text: "", value: "search", width: "5%" },
        { text: "", value: "clear", width: "5%" },
        { text: "", value: "add", width: "5%" }
      ];
    }
  },
  data() {
    return {
      categoryName: "",
      cityName: "",
      totalRecommendedCategories: 0,
      recommendedCategories: [],
      loading: true,
      pagination: {},
      showEditRecommendedCategoryDialog: false,
      showInsertRecommendedCategoryDialog: false,
      citiesList: [],
      categoriesList: [],
      dialogDelete: false,
      itemToDelete: null,
      errors: []
    };
  },
  watch: {
    pagination: {
      handler() {
        this.retrieveRecommendedCategories();
      },
      deep: true
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.name }]);
  },
  components: {
    ConfirmDialog
  },
  methods: {
    async delRecord(item) {
      if (
        await this.$refs.confirm.open(
          this.$i18n.t("common.confirm"),
          this.$i18n.t("pages.recommendedCategories.deleteCatMessage") + item.id + " ?"
        )
      ) {
        this.deleteRecord(item);
      }
    },
    deleteRecord(item) {
      this.deleteRecommendedCategory(item.id);
    },
    deleteRecommendedCategory(recommendedCategory) {
      this.loading = true;
      ApiService.delete(`api/recommended-categories/${recommendedCategory}`)
        .then(response => {
          this.$log.debug("Recommended category deleted: ", response);
          this.retrieveRecommendedCategories();
        })
        .catch(error => {
          this.$log.error("Error: ", error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
    getRequestParams(cityName, categoryName) {
      let params = {};

      if (cityName) {
        params["cityName"] = cityName;
      }

      if (categoryName) {
        params["categoryName"] = categoryName;
      }

      let sort;
      let sortBy = this.pagination.sortBy;
      let sortDesc = this.pagination.sortDesc;
      if (sortBy.length === 1 && sortDesc.length === 1) {
        sort = sortBy[0];
        if (sortDesc[0] === true) sort += ",desc";
      }
      params["sort"] = sort;
      params["page"] = this.pagination.page;
      params["size"] = this.pagination.itemsPerPage;

      return params;
    },
    clearSearch() {
      this.cityName = "";
      this.categoryName = "";
    },
    retrieveRecommendedCategories() {
      const params = this.getRequestParams(this.cityName, this.categoryName);
      return new Promise(resolve => {
        this.loading = true;
        ApiService.query("api/recommended-categories", {
          params
        }).then(response => {
          // JSON responses are automatically parsed.
          this.$log.debug("RecommendedCategory: ", response.data.content);
          this.recommendedCategories = response.data.content;
          this.totalRecommendedCategories = response.data.totalElements;
          this.totalPages = response.data.totalPages;
          this.loading = false;
          let title = this.totalRecommendedCategories != null ? this.totalRecommendedCategories : 0;
          this.$store.dispatch(SET_BREADCRUMB, [{ title: `${this.name}: ` + title }]);
          resolve();
        });
      });
    }
  }
};
</script>

<style>
.v-data-table.v-data-table.v-data-table >>> td {
  font-size: 16px;
}

.v-icon.v-icon {
  font-size: 24px !important;
}

tbody tr:nth-of-type(even) {
  background-color: rgba(236, 237, 237);
}

tbody tr:nth-of-type(odd) {
  background-color: rgb(250, 250, 250);
}
</style>
